<template>
  <div>
    <div>
      <div class="p-2 pt-1 pr-1 text-left mt-mobile-inset mb-mobile-inset">
        <div class="flex flex-row justify-between items-center mt-1 mb-3">
          <h1 class="ml-2 font-bold text-gray-400 text-md">Feed</h1>
          <div class="mr-1">
            <Button class="p-button-text" @click="toggleFeedSidebar" id="toggleFeedSidebar">
              <i class="text-gray-400 pi pi-times navbar-icon" style="font-size: 1rem"></i>
            </Button>
          </div>
        </div>
        <div v-if="isLoading" class="flex justify-center items-center mt-24 h-full">
          <i class="mr-2 text-gray-400 pi pi-spin pi-spinner" style="font-size: 2rem" />
        </div>
        <div v-else class="pr-1 sidebar-scroll standalone-padding">
          <TabView ref="tabview1" v-model:activeIndex="activePanelIndex">
            <TabPanel header="Upcoming" headerStyle="font-size:0.7rem;">
              <div class="mt-2">
                <SidebarConsultCards :referrals="upComingConsults" label="Upcoming" />
              </div>
            </TabPanel>
            <TabPanel header="Recent" headerStyle="font-size:0.7rem;">
              <div class="mt-2">
                <SidebarConsultCards :referrals="recentConsults" label="Recent" />
              </div>
            </TabPanel>
            <TabPanel v-if="!isNativePlatform()" header="News" headerStyle="font-size:0.7rem;">
              <div class="mt-2">
                <SidebarWhatsNew />
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SidebarWhatsNew from '../ui_layout/SidebarWhatsNew.vue';
import Button from 'primevue/button';
import SidebarConsultCards from '../referral/SidebarConsultCards.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import globals from '../../globals';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  props: ['reload'],
  emits: ['feed-toggle'],
  components: {
    SidebarWhatsNew,
    Button,
    SidebarConsultCards,
    TabPanel,
    TabView,
  },
  computed: {
    ...mapGetters(['loggedInUser', 'sideBarReferrals', 'actingUser', 'isGP']),
    upComingConsults() {
      return this.sideBarReferrals
        .filter((referral) => {
          if (this.isGP(this.loggedInUser)) {
            return (referral.interaction_type.name === 'phone' && referral.rebooked_referral_id === null && (referral.status == 'Upcoming' || referral.status == 'Pending')) || (referral.interaction_type.name === 'econsult' && (referral.status == 'In Review' || referral.status == 'Pending'));
          } else {
            return (referral.interaction_type.name === 'phone' && referral.rebooked_referral_id === null && referral.status == 'Upcoming') || (referral.interaction_type.name === 'econsult' && (referral.status == 'In Review' || referral.status == 'Pending'));
          }
        })
        .sort((a, b) => a.start_datetime - b.start_datetime)
        .sort((a, b) => {
          if (b.call_asap == false) {
            return -1;
          } else if (a.call_asap == true) {
            return 1;
          }
          return 0;
        });
    },
    recentConsults() {
      let twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      let today = new Date();
      today.setHours(23, 59, 59);
      return this.sideBarReferrals
        .filter((referral) => {
          let referralStartDateTime = new Date(referral.start_datetime);
          if (this.isGP(this.loggedInUser)) {
            return referralStartDateTime > twoWeeksAgo && referralStartDateTime < today;
          } else {
            return referralStartDateTime > twoWeeksAgo && referralStartDateTime < today && referral.status != 'Pending';
          }
        })
        .sort((a, b) => b.start_datetime - a.start_datetime);
    },
  },
  methods: {
    ...mapActions(['getReferralsPagination']),
    toggleFeedSidebar(event) {
      this.$emit('feed-toggle', event);
    },
  },
  created() {},
  watch: {
    // Fetches user referrals if reload is passed to component
    // Reload triggered on when this sidebar is made visible
    // Either by being activated on route change or button
    reload(newVal, oldVal) {
      if (this.sideBarReferrals.length == 0 && this.reload) {
        // Works but referral statuses are now not getting updated
        this.isLoading = true;
        this.getReferralsPagination({ url: globals.APIs.getReferralsPaginationUrl(), filters: [{ name: 'interactions_types', value: ['phone'] }], sort: { sortField: 'start_datetime', sortOrder: 'desc' }, user_id: this.actingUser.id, page_size: 25, required_relations: ['referral_form', 'referee', 'referral_person', 'interaction_type', 'specialty'], setSideBarReferrals: true })
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
  },
};
</script>

<style scoped>
i {
  font-size: 1.2rem !important;
}

.sidebar-scroll {
  overflow: auto;
  overflow-y: visible;
  direction: ltr;
  height: 90vh;
  overscroll-behavior: contain;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 3px;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background-color: #828690; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 3px solid #d9deec; /* creates padding around scroll thumb */
}
</style>
