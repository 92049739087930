<template>
  <div class="flex flex-col w-full text-start">
    <div for="phoneNumber" class="">
      <label v-if="!phoneNumberError" class="auth-input">Cell Number <span class="text-red-500" v-if="isRequired">*&nbsp;</span></label>
      <label v-else class="auth-input !text-red-500"> Phone number must be a 10 digit number </label>
    </div>
    <InputMask type="tel" mask="(999)-999-9999" id="phoneNumberInput" name="phoneNumber" v-model="phoneNumberWitMask" maxlength="16" required :autoClear="false" class="auth-input" @change="validatePhoneNumber" />
  </div>
</template>

<script>
export default {
  components: {},
  props: ['loggedInUser', 'existingPhoneNumber', 'isRequired'],
  emits: ['validatedPhoneNumber'],
  data() {
    return {
      phoneNumberError: false,
      phoneNumberWitMask: '',
      validatedPhoneNumber: null,
      errors: {},
    };
  },
  methods: {
    validatePhoneNumber() {
      let phoneNumber = this.phoneNumberWitMask.replace(/[^0-9]/g, '');
      if (!/[0-9]/.test(phoneNumber) || phoneNumber.length != 10) {
        this.phoneNumberError = true;
        this.errors.phone_number = 'Please enter a 10 digit phone number';
        this.$emit('validatedPhoneNumber', null);
      } else {
        this.phoneNumberError = false;
        this.validatedPhoneNumber = phoneNumber;
        this.$emit('validatedPhoneNumber', this.validatedPhoneNumber);
      }
    },
  },
  created() {
    if (this.existingPhoneNumber) {
      this.phoneNumberWitMask = this.existingPhoneNumber;
    }
  },
};
</script>

<style>
.p-multiselect-label-container {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
