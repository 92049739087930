import axios from 'axios';
import globals from '../../globals';

const state = {
  courseTopics: [],
  selectedCourseTopic: null,
  selectedCourse: null,
  courseModule: null,
  lastAssessmentAttempt: null,
};

const getters = {
  courseTopics: (state) => {
    return state.courseTopics;
  },
  selectedCourseTopic: (state) => {
    return state.selectedCourseTopic;
  },
  selectedCourse: (state) => {
    return state.selectedCourse;
  },
  courseModule: (state) => {
    return state.courseModule;
  },
  lastAssessmentAttempt: (state) => {
    return state.lastAssessmentAttempt;
  },
};

const actions = {
  getCourseTopics(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getCourseTopicsUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setCourseTopics', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  },
  getCourseTopic(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getCourseTopicUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedCourseTopic', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  },
  getCourse(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getCourseUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSelectedCourse', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  },
  getCourseModule(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getCourseModuleUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setCourseModule', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  },
  submitCourseTopicAccessCode(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getSubmitCourseTopicAccessCodeUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  },
  submitCourseAssessment(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getCourseAssessmentUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let newCourseModule = context.getters.courseModule;
          newCourseModule.cme_credits = [response.data.data];

          let selectedCourse = context.getters.selectedCourse;
          selectedCourse.modules = selectedCourse?.modules?.map((oldCourseModule) => {
            if (oldCourseModule.id === newCourseModule.id) {
              return { ...oldCourseModule, ...newCourseModule };
            }
            return oldCourseModule;
          });

          context.commit('setSelectedCourse', selectedCourse);
          context.commit('setCourseModule', newCourseModule);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  },
  createCourseModuleUserContentInteraction(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.getCreateCourseModuleUserContentInteractionUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let courseModule = context.getters.courseModule;
          courseModule.user_content_interactions = response.data.data?.user_content_interactions;
          context.commit('setCourseModule', courseModule);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  },
  getLastAssessmentAttempt(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getLastAssessmentAttemptUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setLastAssessmentAttempt', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error?.response);
        });
    });
  },
};

const mutations = {
  setCourseTopics(state, data) {
    state.courseTopics = data;
  },
  setSelectedCourseTopic(state, data) {
    state.selectedCourseTopic = data;
  },
  setSelectedCourse(state, data) {
    data.modules.forEach((module) => {
      let status = null;

      if (!status && module.cme_credits && module.cme_credits.length > 0) {
        status = 'Complete';
      }

      if (!status && module.id == data.start_module_id) {
        status = 'Unlocked';
      }

      if (!status) {
        const previousModule = data.modules.find((previous_module) => {
          return previous_module.next_module_id == module.id;
        });
        if (previousModule && previousModule.cme_credits && previousModule.cme_credits.length > 0) {
          status = 'Unlocked';
        }
      }

      if (!status) {
        status = 'Locked';
      }

      module.status = status;
    });
    state.selectedCourse = data;
  },
  setCourseModule(state, data) {
    state.courseModule = data;
  },
  setLastAssessmentAttempt(state, data) {
    state.lastAssessmentAttempt = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
