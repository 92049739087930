<template>
  <!-- Todo: exclude current lecture from carousel. Low priority -->
  <section id="related_lectures" class="flex justify-center w-full px-6 py-4 bg-gray-100 bg-cover md:px-12">
    <div class="w-full max-w-6xl">
      <div class="flex flex-col items-start w-full mt-6 mb-4 text-2xl font-bold">
        <h1 class="mb-4">Related Lectures</h1>
        <div>
          <div class="c-divider"></div>
        </div>
      </div>
      <Carousel :value="pastLectures" :numVisible="3" :numScroll="1" :showNavigators="false" :responsiveOptions="responsiveOptions" circular>
        <template #item="slotProps">
          <div class="flex flex-col py-5 m-2 text-center cursor-pointer md:px-2" @click="goToLectureId(slotProps.data.id)">
            <div class="relative h-64 overflow-hidden v-r-top video-background" :style="{ backgroundImage: `url(${slotProps.data.lecture_image_location})` }">
              <div v-if="slotProps.data.cme_credit_available > 0" class="flex flex-row items-center px-3 py-2 text-xs font-bold cme-label bg-secondary remove-later">
                <i class="mr-1 pi pi-star-fill" style="font-size: 0.8rem" />
                <h1 class="font-bold">One CME Credit</h1>
              </div>
            </div>
            <div class="flex flex-col p-4 text-left text-gray-600 bg-white rounded-b-xl border-1">
              <div class="pb-2 mb-2 font-bold border-b text-md">
                <h1>{{ slotProps.data.lecture_title }}</h1>
              </div>
              <div class="flex flex-row items-center justify-between w-full border-round">
                <div class="flex flex-col">
                  <h3 class="text-sm font-bold">Dr. {{ slotProps.data.presenter_name }}</h3>
                  <h3 class="text-sm font-bold">{{ slotProps.data.presenter_specialty }}</h3>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Carousel>
      <div class="flex flex-row justify-center w-full mb-8 text-sm font-semibold cursor-pointer align-start hover:text-gray-500">
        <h1 @click="$router.push('/lectures')"><span class="border-b-2 border-gray-600 border-solid hover:border-gray-500">View All Lectures</span></h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['pastLectures'],
  data() {
    return {
      isLoading: false,
      responsiveOptions: [
        {
          breakpoint: '1199px',
          numVisible: 3,
          numScroll: 1,
        },
        {
          breakpoint: '1150px',
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  methods: {
    goToLectureId(id) {
      this.$router.push('/lecture/' + id);
    },
  },
};
</script>
<style></style>
