<template>
  <section v-if="!loggedInUser" class="bg-secondary">
    <div class="px-6 py-12 text-left text-white md:px-12">
      <div class="container mx-auto xl:px-4" style="max-width: 1120px">
        <div class="flex flex-col items-center justify-center w-full mx-auto lg:flex-row">
          <div class="max-w-lg mt-0 mb-8 mr-0 lg:mr-10" :class="[isNativePlatform() ? 'pt-24' : '', hasSubscribedToPoems ? 'text-center lg:text-center' : 'text-center lg:text-left']">
            <h1 class="mb-1 text-2xl font-bold tracking-tight sm:text-3xl xl:text-4xl sm:mb-6">
              {{ selectedLanguage == 'En' ? 'Stay Current, Stay Sharp' : 'Restez à jour, restez informé' }}
            </h1>
            <p>
              {{ selectedLanguage == 'En' ? 'POEMs are daily, expert-reviewed summaries of the latest clinical research delivered to your inbox. Enhance your knowledge and earn credits - all before your next patient.' : 'Les POEMs sont des résumés quotidiens, revus par des experts, des dernières recherches cliniques, livrés directement dans votre boîte de réception. Améliorez vos connaissances et gagnez des crédits - le tout avant votre prochain patient.' }}
            </p>
            <div v-if="hasSubscribedToPoems" class="text-sm text-center">
              <p class="mt-6 mb-4">
                {{ selectedLanguage == 'En' ? 'Create an Account to Browse POEMs, Claim Credits, and More!' : 'Créez un compte pour parcourir des POEMs, réclamer des crédits et bien plus encore!' }}
              </p>
              <Button :label="selectedLanguage == 'En' ? 'Create Account' : 'Créer un compte'" @click="$refs.poemRegistrationFormModal.openModal()" class="sm:w-72" />
              <div class="mt-6 text-sm text-center font-display">
                {{ selectedLanguage == 'En' ? 'Already have an account?' : 'Vous avez déjà un compte?' }}
                <span class="cursor-pointer text-primary" @click="redirectToLogin"> {{ selectedLanguage == 'En' ? 'Log in here' : 'Connectez-vous ici' }} </span>&nbsp;
              </div>
            </div>
          </div>
          <div class="w-full max-w-lg mb-0 lg:block" v-if="!hasSubscribedToPoems">
            <div class="w-full p-4 mx-auto text-black bg-white rounded-2xl lg:mx-0 animated fade-in-up sm:p-10">
              <SubscribeToPoems @successfullySubscribedToPoems="successfullySubscribedToPoems" :selectedLanguage="selectedLanguage" @onSelectedLanguageChange="onSelectedLanguageChange" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-else class="bg-secondary">
    <div class="px-4 pb-5 text-left text-white md:px-12" :class="isNativePlatform() ? 'pt-32' : 'pt-28'">
      <div class="flex flex-col items-center justify-center w-full mx-auto lg:flex-row">
        <div class="max-w-lg mt-0 mb-2 mr-0 lg:mr-8">
          <h1 class="mb-1 text-2xl font-bold tracking-tight sm:text-3xl xl:text-4xl sm:mb-6">Stay Current, Stay Sharp</h1>
          <p>POEMs are daily, expert-reviewed summaries of the latest clinical research delivered to your inbox. Enhance your knowledge and earn credits - all before your next patient.</p>
        </div>
        <div class="mt-8 mb-0 lg:block lg:mt-0" v-if="!hasNotificationEnabled(loggedInUser, 'poems', 'Email')">
          <div class="w-full max-w-lg p-4 mx-auto text-black bg-white rounded-2xl lg:mx-0 animated fade-in-up sm:p-10">
            <SubscribeToPoems @successfullySubscribedToPoems="successfullySubscribedToPoems" :selectedLanguage="selectedLanguage" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="menu_bar" class="flex flex-col items-center w-full bg-white md:px-4" style="border-bottom: 1px solid #c9c9c9" v-if="loggedInUser">
    <div class="flex items-center justify-start w-full" style="max-width: 1120px">
      <Menubar :model="menuBarItems" class="w-full md:-ml-4">
        <template #item="{ item, props }">
          <a v-ripple class="flex align-items-center" v-bind="props.action">
            <span :class="selectedMenuItem === item.label ? item.selectedIcon : item.icon" />
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </template>
      </Menubar>
      <div class="flex flex-row items-center justify-center h-full">
        <Button :icon="currentSort.sortOrder === 'desc' ? 'pi pi-arrow-down' : 'pi pi-arrow-up'" class="menubar-button" :label="currentSort.sortOrder === 'desc' ? 'Most Recent' : 'Oldest'" @click="onSort({ sortField: 'delivery_datetime', sortOrder: currentSort.sortOrder === 'desc' ? 'asc' : 'desc' })"></Button>
        <Dropdown placeholder="Filter by Specialty" id="lecture_filter_dropdown" :options="poemTags" optionLabel="name" optionValue="id" v-model="selectedTag" showClear @change="onFilterChange" class="p-inputtext-sm" v-if="poemTags.length > 0"></Dropdown>
      </div>
    </div>
  </section>
  <section id="lecture_search_bar" class="flex flex-col items-center w-full bg-white md:px-4" style="border-bottom: 1px solid #c9c9c9" v-if="loggedInUser">
    <div class="flex flex-col items-start justify-start w-full px-2 py-2 md:flex-row md:items-center" style="max-width: 1120px">
      <IconField iconPosition="left" class="w-full">
        <InputIcon class="pi pi-search"> </InputIcon>
        <InputText v-model="searchPoemsValue" placeholder="Search POEMs" class="w-full p-inputtext-sm" style="padding-left: 2.5rem; width: 100%" maxLength="255" @input="onFilterChangeDebounce" />
      </IconField>
    </div>
  </section>

  <div :class="isNativePlatform() ? 'px-5 py-4' : 'main-inner-wrap vld-parent'" ref="poemsContainer">
    <div class="relative w-full px-0 sm:px-4" style="max-width: 1120px" :class="{ 'mt-8': isNativePlatform() }">
      <div v-if="poems.length > 0" class="mt-2">
        <div v-auto-animate class="flex flex-col items-center animated fade-in-up animation-delay">
          <div v-for="poem in poems" :key="poem.id" class="relative px-6 py-3 my-2 w-full max-w-2xl text-left bg-gray-50 rounded-xl border border-gray-300 shadow-sm transition duration-500 ease-in-out transform cursor-pointer sm:px-8 hover:-translate-y-0.5 hover:border-gray-500" @click="selectPoem(poem)" :id="`poem-${poem.id}`">
            <h3 class="font-semibold text-gray-900">{{ poem.title }}</h3>
            <p class="mt-2 text-sm">{{ poem?.bottom_line?.substring(0, 155) }}.....</p>
            <div class="flex flex-row">
              <div class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border rounded-xl flew-row hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="togglePoemLikeOnSubmit(poem)" :id="`poem-like-button-${poem.id}`">
                <i class="pr-3 pi" :class="poem?.likes?.length > 0 ? 'pi-thumbs-up-fill' : 'pi-thumbs-up'"></i>
                <span class="text-sm font-base">{{ poem.likes_count }}</span>
              </div>
              <div class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border rounded-xl flew-row hover:bg-gray-100 hover:border-gray-400" :id="`poem-comment-button-${poem.id}`">
                <i class="pr-2 pi pi-comment"></i>
                <span class="text-sm font-base">{{ poem.comments_count }}</span>
              </div>
              <div class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border rounded-xl flew-row hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="togglePoemBookmarkOnSubmit(poem)" :id="`poem-bookmark-button-${poem.id}`">
                <i class="pi" :class="poem?.bookmarks?.length > 0 ? 'pi-bookmark-fill' : 'pi-bookmark'"></i>
              </div>
              <div class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border rounded-xl flew-row hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="shareOnClick($event, poem)" :id="`poem-share-button-${poem.id}`">
                <i class="pr-0 pi pi-send sm:pr-2"></i>
                <span class="hidden text-sm font-base sm:block">Share</span>
              </div>
              <div v-if="poem?.cme_credits?.length > 0" class="flex items-center justify-center px-3 py-1 mt-2 text-white rounded-xl flew-row bg-secondary">
                <i class="mr-0 text-white fas fa-award sm:mr-2" style="font-size: 0.8rem" />
                <span class="hidden text-sm font-base sm:block">Claimed</span>
              </div>
              <div class="items-center justify-center hidden px-3 py-1 mt-2 sm:flex">
                <span class="text-sm font-base text-nowrap">{{ formatDate(poem.delivery_datetime) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!isLoading" class="relative flex flex-col items-center justify-center w-full mt-10">
        <h1 class="mb-2 text-2xl">No Results</h1>
        <Button label="Clear Filters" @click="clearFilters" class="p-button-xs" style="width: 200px" />
      </div>
      <div v-if="isLoading">
        <div v-auto-animate class="flex flex-col items-center animated fade-in-up animation-delay">
          <div v-for="i in [1, 2, 3]" :key="i" class="w-full max-w-2xl px-8 py-3 my-2 text-left border border-gray-300 shadow-sm bg-gray-50 rounded-xl">
            <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
            <Skeleton height="4rem" class="mb-2" borderRadius="16px"></Skeleton>
            <div class="flex flex-row">
              <Skeleton width="5rem" borderRadius="16px" class="mb-2 mr-2" height="2rem"></Skeleton>
              <Skeleton width="5rem" borderRadius="16px" class="mb-2 mr-2 2" height="2rem"></Skeleton>
              <Skeleton width="5rem" borderRadius="16px" class="mb-2 mr-2 2" height="2rem"></Skeleton>
              <Skeleton width="5rem" borderRadius="16px" class="mb-2 mr-2 2" height="2rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <transition name="slide-up-fade">
        <div v-auto-animate class="fixed flex items-center justify-center w-12 h-12 rounded-full cursor-pointer right-10 bg-secondary" :class="isNativePlatform() ? 'bottom-24' : 'bottom-10'" @click="scrollToTop" v-if="displayScrollToTopButton">
          <i class="text-xl text-white pi pi-angle-up" style="font-size: 1.4rem"></i>
        </div>
      </transition>
    </div>
  </div>
  <section id="lecture_value_props" v-if="!loggedInUser" class="bg-white">
    <div class="max-w-screen-xl pt-4 pb-8 mx-auto" style="max-width: 1120px">
      <div class="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2 lg:grid-cols-3">
        <div class="block p-8">
          <i class="mx-auto pi pi-sign-in text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Sign Up</h2>
        </div>
        <div class="block p-8">
          <i class="mx-auto pi pi-send text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Receive Daily POEMs</h2>
        </div>
        <div class="block p-8">
          <i class="mx-auto pi pi-book text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Read, Learn, and Earn Credits</h2>
        </div>
      </div>
    </div>
  </section>
  <section v-if="!loggedInUser && !hasSubscribedToPoems" class="bg-secondary">
    <div class="px-6 py-12 text-left text-white md:px-12">
      <div class="container mx-auto" style="max-width: 1120px">
        <div class="">
          <div class="mb-0 lg:block">
            <div class="max-w-lg p-4 mx-auto text-black bg-white rounded-2xl animated fade-in-up sm:p-10">
              <SubscribeToPoems @successfullySubscribedToPoems="successfullySubscribedToPoems" :selectedPoem="selectedPoem" :selectedLanguage="selectedLanguage" @onSelectedLanguageChange="onSelectedLanguageChange" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Modal ref="subscribeToPoemsModal" class="mx-auto">
    <template v-slot:body>
      <div class="flex flex-col justify-center items-center sm:w-[500px] max-w-xl px-6 py-8 relative" id="subscribeToPoemsModal">
        <i class="absolute top-3 right-3 p-1.5 text-gray-400 cursor-pointer pi pi-times" @click="$refs.subscribeToPoemsModal.closeModal()" style="font-size: 1rem" id="closeSubscribeToPoemsModalButton"></i>
        <div class="flex flex-col w-full max-w-lg px-2 text-center text-gray-600 text-md">
          <SubscribeToPoems @successfullySubscribedToPoems="successfullySubscribedToPoems" :selectedPoem="selectedPoem" :selectedLanguage="selectedLanguage" @onSelectedLanguageChange="onSelectedLanguageChange" />
        </div>
      </div>
    </template>
  </Modal>
  <PoemRegistrationFormModal ref="poemRegistrationFormModal" :selectedPoem="selectedPoem" :prefillEmail="prefillEmail" :selectedLanguage="selectedLanguage" />
  <Menu ref="menu" style="width: 210px" :model="menuItems" :popup="true"> </Menu>
</template>

<script>
import globals from '../../../globals.js';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Loading from 'vue-loading-overlay';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Skeleton from 'primevue/skeleton';
import Modal from '@/components/misc/Modal.vue';
import PoemRegistrationFormModal from '@/components/poems/PoemRegistrationFormModal.vue';
import { Share } from '@capacitor/share';
import Menu from 'primevue/menu';

import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  components: {
    Button,
    Loading,
    Dialog,
    Dropdown,
    InputText,
    Skeleton,
    Modal,
    PoemRegistrationFormModal,
    Menu,
  },

  data() {
    return {
      prefillEmail: null,
      displayScrollToTopButton: false,
      hasSubscribedToPoems: false,
      menuBarItems: [
        {
          label: 'My Credits',
          icon: 'fas fa-award',
          command: () => {
            this.$router.push('/cme-certifications?type=poem');
          },
        },
        // {
        //   label: 'Popular',
        //   icon: 'pi pi-fw pi-star',
        //   command: () => {
        //     this.scrollToOnDemandAndSort();
        //   },
        // },
        {
          label: 'Saved',
          icon: 'pi pi-bookmark',
          selectedIcon: 'pi pi-bookmark-fill',
          command: () => {
            this.selectedMenuItem = this.selectedMenuItem === 'Saved' ? null : 'Saved';
            this.bookmarked = !this.bookmarked;
            this.unclaimed = false;
            this.onFilterChange();
          },
        },
        {
          label: 'Unclaimed',
          icon: 'pi pi-star',
          selectedIcon: 'pi pi-star-fill',
          command: () => {
            this.selectedMenuItem = this.selectedMenuItem === 'Unclaimed' ? null : 'Unclaimed';
            this.unclaimed = !this.unclaimed;
            this.bookmarked = false;
            this.onFilterChange();
          },
        },
        {
          label: 'Invite a Colleague',
          icon: 'pi pi-share-alt',
          command: () => {
            this.$router.push('/poems-connect');
          },
        },
      ],
      menuItems: [],
      filterValue: '',
      initialRequest: true,
      isLoading: false,
      selectedTag: null,
      searchPoemsValue: null,
      bookmarked: false,
      unclaimed: false,
      mainUrl: globals.APIs.getPoemsUrl(),
      currentUrl: globals.APIs.getPoemsUrl() + '?page=1',
      currentFilters: [],
      currentSort: { sortField: 'delivery_datetime', sortOrder: 'desc' },
      debounceTimer: null,
      selectedPoem: null,
      selectedMenuItem: null,
      selectedLanguage: 'En',
      scrollTimeout: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'poemsPaginator', 'poems', 'poemsScrollPosition', 'poemTags']),
  },
  methods: {
    ...mapActions(['getPoems', 'getPoemTags', 'togglePoemBookmark', 'togglePoemLike', 'createUserAnalytic']),
    ...mapMutations(['setPoemsScrollPosition']),
    selectPoem(poem) {
      this.selectedPoem = poem;
      if (this.loggedInUser) {
        this.$router.push(`/poem/${poem.id}`);
      } else if (this.hasSubscribedToPoems) {
        this.$refs.poemRegistrationFormModal.openModal();
      } else {
        this.$refs.subscribeToPoemsModal.openModal();
      }
    },
    clearFilters() {
      this.selectedTag = null;
      this.searchPoemsValue = null;
      this.bookmarked = false;
      this.unclaimed = false;
      let url = this.mainUrl + '?page=1';
      this.currentUrl = url;
      this.currentSort = { sortField: 'delivery_datetime', sortOrder: 'desc' };
      this.currentFilters = [];
      this.getPoemsMethod();
    },
    getPoemsMethod() {
      this.isLoading = true;
      let pageSize = 10;
      if (this.loggedInUser) {
        pageSize = 25;
      }
      this.getPoems({ url: this.currentUrl, filters: this.currentFilters, sort: this.currentSort, page_size: pageSize, initial_request: this.initialRequest, user_analytic_meta_data: JSON.stringify({ url_query: this.$route.fullPath }) })
        .then(() => {
          this.initialRequest = false;
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch((e) => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    onFilterChange() {
      this.resetDebounceTimer();
      this.currentFilters = [];
      if (this.selectedTag) {
        this.currentFilters.push({ name: 'tags', value: this.selectedTag });
      }
      if (this.searchPoemsValue) {
        this.currentFilters.push({ name: 'title', value: this.searchPoemsValue });
      }
      if (this.bookmarked) {
        this.currentFilters.push({ name: 'bookmarked' });
      }
      if (this.unclaimed) {
        this.currentFilters.push({ name: 'unclaimed' });
      }

      let url = this.mainUrl + '?page=1';
      this.currentUrl = url;
      this.getPoemsMethod();
    },
    resetFilters() {
      // Reset the current filters from the paginator
      if (this.currentFilters.find((filter) => filter.name === 'tags')) {
        this.selectedTag = this.currentFilters.find((filter) => filter.name === 'tags').value;
      }
      if (this.currentFilters.find((filter) => filter.name === 'title')) {
        this.searchPoemsValue = this.currentFilters.find((filter) => filter.name === 'title').value;
      }
      if (this.currentFilters.find((filter) => filter.name === 'bookmarked')) {
        this.bookmarked = true;
        this.selectedMenuItem = 'Saved';
      }
      if (this.currentFilters.find((filter) => filter.name === 'unclaimed')) {
        this.unclaimed = true;
        this.selectedMenuItem = 'Unclaimed';
      }
    },
    onSort(event) {
      this.currentSort = { sortField: event.sortField, sortOrder: event.sortOrder };
      let url = this.mainUrl + '?page=1';
      this.currentUrl = url;
      this.getPoemsMethod();
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollPosition = scrollTop / scrollHeight;
      this.setPoemsScrollPosition(window.scrollY);

      this.displayScrollToTopButton = scrollTop >= 500;
      if (scrollPosition >= 0.75 && !this.isLoading && this.currentUrl.split('?page=')[1] !== this.poemsPaginator.last_page_url.split('?page=')[1]) {
        if (!this.loggedInUser) {
          return;
        }
        const currentPage = parseInt(this.currentUrl.split('?page=')[1]);
        this.currentUrl = this.mainUrl + `?page=${currentPage + 1}`;
        this.getPoemsMethod();
      }
    },
    resetDebounceTimer() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = null;
      }
    },
    onFilterChangeDebounce() {
      this.resetDebounceTimer();
      this.debounceTimer = setTimeout(() => {
        this.onFilterChange();
      }, 300);
    },
    togglePoemBookmarkOnSubmit(poem) {
      if (!this.loggedInUser) {
        this.selectedPoem = poem;
        if (this.hasSubscribedToPoems) {
          this.$refs.poemRegistrationFormModal.openModal();
        } else {
          this.$refs.subscribeToPoemsModal.openModal();
        }
        return;
      }
      this.togglePoemBookmark(poem)
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    togglePoemLikeOnSubmit(poem) {
      if (!this.loggedInUser) {
        this.selectedPoem = poem;
        if (this.hasSubscribedToPoems) {
          this.$refs.poemRegistrationFormModal.openModal();
        } else {
          this.$refs.subscribeToPoemsModal.openModal();
        }
        return;
      }
      this.togglePoemLike(poem)
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    async shareOnClick(event, poem) {
      const url = `https://app.virtualhallway.ca/api/poem/view/${poem.id}`;

      if (this.isNativePlatform()) {
        try {
          await Share.share({
            title: 'Virtual Hallway POEMs',
            text: poem.title,
            url,
            dialogTitle: 'Share POEMs Link',
          });
        } catch (e) {}
      } else {
        this.setShareLinks(poem);
        this.$refs.menu.toggle(event);
      }
    },
    setShareLinks(poem) {
      const baseUrl = `https://app.virtualhallway.ca/api/poem/view/${poem.id}`;
      const message = `Virtual Hallway Presents - POEM - ${poem.title}`;
      this.menuItems = [
        {
          label: 'Facebook',
          icon: 'pi pi-facebook',
          command: () => {
            const url = encodeURI(`https://www.facebook.com/share_channel/?link=${baseUrl}&quote=${message}&source_surface=external_reshare&display&hashtag=#CME`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'LinkedIn',
          icon: 'pi pi-linkedin',
          command: () => {
            const url = encodeURI(`https://www.linkedin.com/sharing/share-offsite/?url=${baseUrl}&title=${message}`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'Twitter',
          icon: 'pi pi-twitter',
          command: () => {
            const url = encodeURI(`https://x.com/intent/post?text=${message}&url=${baseUrl}&hashtags=#CME`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'Copy Link',
          icon: 'pi pi-link',
          command: () => {
            this.copyToClipboard(poem);
          },
        },
      ];
    },
    copyToClipboard(poem) {
      let hostname = window.location.hostname;
      if (hostname === 'localhost') {
        hostname = 'localhost:8080';
      }
      const url = `${hostname}/poem/${poem.id}`;

      navigator.clipboard.writeText(url);
      this.$toast.add({
        severity: 'success',
        summary: 'Link Copied',
        life: 2000,
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    successfullySubscribedToPoems(event) {
      this.prefillEmail = event.prefillEmail;
      this.$refs.subscribeToPoemsModal.closeModal();
      if (!this.loggedInUser) {
        this.hasSubscribedToPoems = true;
        this.$refs.poemRegistrationFormModal.openModal();
      }
    },
    redirectToLogin() {
      if (this.selectedPoem) {
        this.$router.push(`/login?redirect=poem/${this.selectedPoem.id}`);
      } else {
        this.$router.push(`/login?redirect=poems`);
      }
    },
    onSelectedLanguageChange(event) {
      this.selectedLanguage = event;
    },
  },
  created() {
    if (this.$route.query.lang) {
      this.selectedLanguage = this.$route.query.lang;
    }

    if (this.poemTags.length === 0) {
      this.getPoemTags()
        .then(() => {})
        .catch(() => {});
    }
    if (this.poemsPaginator) {
      this.currentFilters = this.poemsPaginator.filters;
      this.currentSort = this.poemsPaginator.sort;
      this.currentUrl = this.poemsPaginator.url;
      this.resetFilters();
      if (this.poemsScrollPosition) {
        this.$nextTick();
        this.$nextTick(function () {
          setTimeout(() => {
            window.scrollTo({ top: this.poemsScrollPosition, behavior: 'instant' });
          }, 5);
        });
      }

      return;
    }

    this.currentSort = { sortField: 'delivery_datetime', sortOrder: 'desc' };
    this.getPoemsMethod();

    if (this.isIphoneBrowserButNotIosApp()) {
      window.location.href = `virtualhallway://login?redirect=${this.$route.fullPath}`;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    loggedInUser(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.$refs.poemRegistrationFormModal.closeModal();
        this.getPoemsMethod();
      }
    },
  },
};
</script>

<style>
.p-menubar-root-list {
  min-width: 230px;
}
.dark-blue {
  color: #5b76a2;
}
.subheading-blue {
  color: #859dc4;
}
.video-background {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: gray;
}
.cme-label {
  color: white;
  top: 0;
  left: 0;
  position: absolute;
  border-top-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.cme-live-label {
  color: white;
  background-color: #ef5164;
  top: 0;
  left: 0;
  position: absolute;
  border-top-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  z-index: 2;
}
.credit-claimed-label {
  color: white;
  bottom: 1.5rem;
  right: 0;
  position: absolute;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.image-alt-rounded-corners {
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
}
.p-menubar {
  background: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 0.8rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: #394353;
}

.p-menubar .p-menubar-root-list > .p-menuitem:hover {
  background: #f0f2f4;
  border-radius: 5px;
}
.p-menuitem {
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    flex-direction: row-reverse;
    justify-content: start;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link > svg,
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link > .pi {
    margin-left: 0.5rem;
  }
}
.menubar-button {
  border: none;
  font-family: 'Poppins';
  font-size: 0.8rem;
  border: none;
  font-weight: 600;
  color: rgb(57, 67, 83);
  background: #ffffff;
  text-wrap: nowrap;
  width: 130px;
  text-align: left;
}

.menubar-button:enabled:hover {
  background: #f0f2f4;
  color: rgb(57, 67, 83);
}
</style>
